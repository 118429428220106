<template>
  <v-icon v-bind="$attrs" v-on="$listeners">{{ svgPath }}</v-icon>
</template>

<script>
import { mdiLockClock } from '@mdi/js';

export default {
  data: () => ({
    svgPath: mdiLockClock,
  }),
};
</script>
